import '@rainbow-me/rainbowkit/styles.css';
import {
  darkTheme,
  getDefaultWallets,
  RainbowKitProvider,
  lightTheme,
  AvatarComponent,
} from '@rainbow-me/rainbowkit';
import { WagmiConfig, configureChains, createConfig } from 'wagmi';
import { polygon } from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';

import { useColorMode } from '@chakra-ui/react';
import Jazzicon from 'react-jazzicon/dist/Jazzicon';
import { jsNumberForAddress } from 'react-jazzicon';

require('dotenv').config();

const projectId = '21bc3cf01fade70639b3ec6a14e6277c';

if (!projectId) {
  throw new Error('WalletConnect project id is not defined');
}

const { publicClient, chains } = configureChains([polygon], [publicProvider()]);

const { connectors } = getDefaultWallets({
  appName: 'MarsNext',
  projectId: '21bc3cf01fade70639b3ec6a14e6277c',
  chains,
});

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
});

const CustomAvatar: AvatarComponent = ({ address, ensImage, size }) => {
  return (
    <Jazzicon seed={jsNumberForAddress(`${address}`)} diameter={100}></Jazzicon>
  );
};

export const ProviderRainbow = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { colorMode } = useColorMode();
  return (
    <>
      <WagmiConfig config={wagmiConfig}>
        <RainbowKitProvider
          chains={chains}
          theme={colorMode === 'dark' ? darkTheme() : lightTheme()}
          showRecentTransactions={true}
          avatar={CustomAvatar}
          coolMode
          modalSize='wide'

        >
          {children}
        </RainbowKitProvider>
      </WagmiConfig>
    </>
  );
};
