import { ArrowForwardIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  HStack,
  Heading,
  Icon,
  Image,
  Input,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Tag,
  Text,
  VStack,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAccount, useBalance, useContractWrite, useNetwork } from 'wagmi';
import { AddressZero } from '../../constants/ContractAddress';
import {
  minPresaleContribution,
  presaleRate,
  supportedNetworkInfo,
} from '../../constants/SupportedNetworkInfo';
import { useGetAllowance } from '../../hooks/ERC20Token';
import { useGetUserTeam } from '../../hooks/ReferralHooks';
import { CenterComponent } from '../../util/Ui';
import { ModalAllowance } from '../Modals/ModalAllowance';
import ModalConfirmTransactions from '../Modals/ModalConfirmTransactions';
import ModalTransactionSuccess from '../Modals/ModalTransactionSuccess';
const MotionIcon = motion(Icon);

export const StakeUi = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const { address } = useAccount();
  const { referrerAddress } = useParams<{
    referrerAddress: `0x${string}`;
  }>();
  const currentReferrer = referrerAddress ? referrerAddress : AddressZero;
  const { chains, chain } = useNetwork();
  const currentNetwork = supportedNetworkInfo[chain?.id!];
  const userTeamObject = useGetUserTeam(address);
  const userUSDTBalance = useBalance({
    address: address,
    token: currentNetwork?.USDT?.contractAddress,
  });

  const userTokenBalance = useBalance({
    address: address,
    token: currentNetwork?.Token?.contractAddress,
  });

  const userAllowanceValue = useGetAllowance(
    currentNetwork?.USDT.contractAddress,
    address!,
    currentNetwork?.referralContractAddress!
  );

  const [userInput, setUserInput] = useState<{
    ethInput: string;
    tokenInput: string;
  }>({
    ethInput: '',
    tokenInput: '',
  });

  const handleETHInput = (e: any) => {
    const inputValue = e.target.value;
    setUserInput({
      ethInput: inputValue,
      tokenInput: `${
        Number(inputValue * presaleRate) > 0
          ? Number(inputValue * presaleRate)
          : ''
      }`,
    });
  };
  const handleTokenInput = (e: any) => {
    const inputValue = e.target.value;
    setUserInput({
      ethInput: `${
        Number(inputValue / presaleRate) > 0
          ? Number(inputValue / presaleRate)
          : ''
      }`,

      tokenInput: inputValue,
    });
  };

  const {
    data,
    isLoading,
    isSuccess,
    write,
    status,
    reset,
    writeAsync,
    error: writeContractHookError,
    isError,
    isIdle,
  } = useContractWrite({
    address: currentNetwork?.referralContractAddress,
    abi: currentNetwork?.referralContractInterface,
    functionName: 'registrationWithTokenAndStake',
    args: [
      address,
      currentReferrer,
      Number(userInput.ethInput) > 0
        ? Number(userInput.ethInput) * 10 ** currentNetwork?.USDT?.decimals
        : 0,
      currentNetwork?.USDT?.contractAddress,
    ],
  });

  const isUserAllowanceSufficient =
    userAllowanceValue >= Number(userInput.ethInput) ? true : false;

  const errors = {
    insufficientBalance:
      userUSDTBalance.data?.formatted &&
      Number(userUSDTBalance.data?.formatted) < minPresaleContribution
        ? true
        : false,
    insufficientAllowance: isUserAllowanceSufficient,
    isUserAlreadyHaveReferrer:
      userTeamObject?.referrer !== AddressZero ? true : false,
  };

  const proceedTransaction = () => {
    if (errors.insufficientBalance) {
      toast({
        title: 'Insufficient Balance.',
        description: `You atleast need ${minPresaleContribution} USDT to swap & stake.`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } else if (
      userInput.ethInput.length === 0 &&
      Number(userInput.ethInput) === 0
    ) {
      toast({
        title: 'Value field empty.',
        description: `Please enter the value.`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } else if (
      userInput.tokenInput.length === 0 &&
      Number(userInput.tokenInput) === 0
    ) {
      toast({
        title: 'Value field empty.',
        description: `Please enter the value.`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } else {
      onOpen();
    }
  };

  const handleTransaction = async () => {
    try {
      await writeAsync();
    } catch (err: any) {
      const errorMessage = JSON.stringify(err);
      toast({
        title: JSON.parse(errorMessage)?.shortMessage,
        description: '',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <VStack spacing={10} minW={275} maxW={500} w="full" p={5}>
        <VStack>
          <Heading color="twitter.500" size="4xl">
            Swap & Stake{' '}
            <Box as="span" color={useColorModeValue('black', 'white')}>
              {currentNetwork?.Token?.name}
            </Box>
          </Heading>
          <Text fontSize="2xl">
            To earn staking rewards{' '}
            <Tag colorScheme="pink" borderRadius="full" fontSize="2xl">
              12% APY
            </Tag>{' '}
            {currentNetwork?.Token?.symbol}
          </Text>
        </VStack>
        <CenterComponent
          style={{
            w: 'full',
            flexDirection: 'column',
            boxShadow:
              '0px 5px 500px 2px rgba(29, 161, 242, 0.3), 0px 5px 50px 2px rgba(237, 100, 166, 0.3)',
          }}
        >
          <Flex w="full" h="full" direction="column" gap={5}>
            <Heading size="md">Swap & Stake</Heading>
            <Flex
              w="full"
              direction="column"
              // bgColor={useColorModeValue('gray.200', 'whiteAlpha.300')}
              p={2}
              borderRadius="25px"
              borderWidth="thin"
            >
              <Flex p={2}>
                <FormControl display="flex" gap={2} flexDirection="column">
                  <HStack justify="space-between" flexDirection="row">
                    <Text>You pay</Text>
                    <Text>
                      {Number(userUSDTBalance.data?.formatted)?.toFixed(3)}{' '}
                      {userUSDTBalance?.data?.symbol}
                    </Text>
                  </HStack>
                  <HStack>
                    <Input
                      type="number"
                      placeholder="0"
                      borderWidth={0}
                      fontSize="5xl"
                      h={20}
                      borderRadius="3xl"
                      value={userInput?.ethInput}
                      onChange={handleETHInput}
                    />
                    <Tag minW="fit-content" borderRadius="full" p={0}>
                      <Image
                        src={currentNetwork?.USDT.logo}
                        boxSize={10}
                      ></Image>
                      {/* <Text>MATIC</Text> */}
                    </Tag>
                  </HStack>

                  <FormHelperText>
                    Min contribution 100 {userUSDTBalance?.data?.symbol}
                  </FormHelperText>
                </FormControl>
              </Flex>
            </Flex>
            <Flex
              w="full"
              direction="column"
              // bgColor={useColorModeValue('gray.200', 'whiteAlpha.300')}
              p={2}
              borderRadius="25px"
              borderWidth="thin"
              minH={180}
            >
              <Flex p={2}>
                <FormControl display="flex" gap={2} flexDirection="column">
                  <HStack justify="space-between" flexDirection="row">
                    <Text>You get & stake</Text>
                    <Text>
                      {Number(userTokenBalance.data?.formatted)?.toFixed(3)}{' '}
                      {userTokenBalance.data?.symbol}
                    </Text>
                  </HStack>
                  <HStack>
                    <Input
                      type="number"
                      placeholder="0"
                      borderWidth={0}
                      fontSize="5xl"
                      h={20}
                      borderRadius="3xl"
                      value={userInput?.tokenInput}
                      onChange={handleTokenInput}
                    />
                    <Tag minW="fit-content" borderRadius="full" p={0}>
                      <Image
                        src={currentNetwork?.Token?.logo}
                        boxSize={10}
                      ></Image>
                      {/* <Text>MATIC</Text> */}
                    </Tag>
                  </HStack>
                  {userInput.ethInput.length > 0 && (
                    <FormHelperText>+ 1 Mars Astronaut NFT</FormHelperText>
                  )}
                </FormControl>
              </Flex>
            </Flex>

            <Button
              w="full"
              h={20}
              borderRadius="3xl"
              colorScheme="twitter"
              fontSize="xl"
              rightIcon={
                <MotionIcon
                  as={ArrowForwardIcon}
                  animate={{
                    x: [0, 5, 0],
                  }}
                  transition={{
                    duration: 1,
                    repeat: Infinity,
                  }}
                ></MotionIcon>
              }
              onClick={proceedTransaction}
              isDisabled={
                isLoading ||
                userInput.ethInput.length === 0 ||
                Number(userInput.ethInput) === 0
              }
              isLoading={isLoading}
              loadingText={status}
            >
              Stake
            </Button>
          </Flex>
        </CenterComponent>
      </VStack>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        size={['xs', 'md', 'lg']}
      >
        <ModalOverlay />
        <ModalContent
          borderRadius="25px"
          // bgColor={useColorModeValue('whiteAlpha.900', 'blackAlpha.200')}
          backdropFilter="blur(20px)"
          borderWidth={1}
          borderBottomWidth={5}
        >
          <ModalCloseButton />
          {!isSuccess ? (
            !errors.insufficientAllowance ? (
              <ModalAllowance
                onClose={onClose}
                spenderAddress={currentNetwork?.referralContractAddress!}
                valueToApprove={
                  Number(userInput.ethInput) > 0
                    ? BigInt(
                        Number(userInput.ethInput) *
                          10 ** currentNetwork?.USDT.decimals
                      )
                    : BigInt(0)
                }
                tokenObject={currentNetwork?.USDT}
              />
            ) : (
              <ModalConfirmTransactions
                onClose={onClose}
                onConfirm={handleTransaction}
                transactionName={`Swap & Stake ${currentNetwork?.Token?.name}`}
                outCurrencyObject={currentNetwork?.USDT}
                outCurrencyValue={
                  Number(userInput.ethInput) > 0
                    ? Number(userInput.ethInput)
                    : 0
                }
                buttonProps={{
                  isLoading: isLoading,
                  isDisabled: isLoading,
                  loadingText: 'Confirming',
                }}
              ></ModalConfirmTransactions>
            )
          ) : (
            <ModalTransactionSuccess
              onClose={() => {
                onClose();
                reset();
              }}
              transactionHash={`${data?.hash}`}
              currentNetwork={currentNetwork}
            ></ModalTransactionSuccess>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};
