'use client';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { Button, Heading, Text, VStack } from '@chakra-ui/react';
import { IoMdFlash } from 'react-icons/io';
import { Link } from 'react-router-dom';

export const HeaderHeadingComponent = () => {
  return (
    <VStack spacing={5}>
      <VStack
        fontSize={['5xl']}
        fontWeight={700}
        fontFamily="heading"
        lineHeight={0.9}
        textAlign="center"
      >
        {/* <Heading>Build for</Heading>
        <Heading>Community</Heading> */}
        <Text>A Set of Secured Smart Contracts</Text>
        <Text>For Reward Distribution Protocol</Text>
      </VStack>
      <Text
        fontSize={['xl']}
        textAlign="center"
        px={5}
        lineHeight={1.2}
        maxW={500}
      >
        MarsNext is a community centric & fully decentralized reward
        distribution protocol build on secured smart contracts powered by OpenZeppelin.
      </Text>
      <Link to="/user">
        <button></button>
        <Button
          w={[250, 300]}
          h={16}
          borderRadius="3xl"
          rightIcon={<ChevronRightIcon />}
          leftIcon={<IoMdFlash/>}
          zIndex={1}
          borderBottomWidth="thick"
          variant="solid"
          borderWidth="thin"
        >
          Launch App
        </Button>
      </Link>
    </VStack>
  );
};
