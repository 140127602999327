import { Image, VStack } from '@chakra-ui/react';
import { Header } from './Header';
import { PowerOfBlockchainComponent } from './PowerOfBlockchainComponent/PowerOfBlockchainComponent';
import { TokenDistribution } from './TokenDistribution/TokenDistribution';
import WeeklyReward from './WeeklyReward/WeeklyReward';

export const Home = () => {
  return (
    <VStack w="full">
      <Header />
      <PowerOfBlockchainComponent />
      {/* <PlanDiscriptionComponent /> */}
      {/* <TokenDistribution /> */}
       {/* <WeeklyReward /> */}
    </VStack>
  );
};
