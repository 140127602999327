import { CenterComponent, HeadingComponent } from '../../../util/Ui';
import {
  Box,
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  Image,
  Stack,
  Text,
  VStack,
  Wrap,
  useColorModeValue,
} from '@chakra-ui/react';
import { IconType } from 'react-icons';
import { BsBox, BsLock, BsShieldCheck } from 'react-icons/bs';
import { FaGithub, FaUserLock } from 'react-icons/fa';
import { GiCubes, GiWineGlass } from 'react-icons/gi';

const fetures = [
  {
    icon: BsBox,
    heading: 'Transparent',
    text: 'Each and every logic is written on smart contracts, which means each or any action is visible and can crosschecked.',
  },
  {
    icon: BsLock,
    heading: 'Secured',
    text: 'As we are fully decentralized & everything is on smart contract, which is most secured in the world.',
  },
  {
    icon: FaUserLock,
    heading: 'Permissionless',
    text: 'With no outside control, your funds are always safe.',
  },
  {
    icon: FaGithub,
    heading: 'Open Source',
    text: 'Our each and every code is open source on github. Anyone can audit our code, whether its frontend or smart contract logic.',
  },
];

export const PowerOfBlockchainComponent = () => {
  return (
    <VStack borderWidth="thin" borderRadius="100px" w="full">
      <Stack
        direction={['column', 'column', 'column', 'column', "row"]}
        p={[5, 10]}
        // gap={10}
        justify="center"
        align="center"
      >
        <Box maxW={500}>
          <Image src="/marsAstro.png"></Image>
        </Box>
        <Wrap spacing={5} justify="center">
          {fetures?.map((featuresObject, key) => {
            return (
              <Flex
                key={key}
                direction="column"
                gap={5}
                maxW={300}
                p={10}
                w="full"
                borderWidth="thin"
                borderRadius="3xl"
              >
                <Icon
                  as={featuresObject?.icon}
                  boxSize={10}
                  color="pink.500"
                ></Icon>
                <Heading size="lg">{featuresObject?.heading}</Heading>
                <Text>{featuresObject?.text}</Text>
              </Flex>
            );
          })}
        </Wrap>
      </Stack>
    </VStack>
  );
};
