'use client';
import {
  Flex,
  Heading,
  Icon,
  Image,
  Text,
  VStack,
  Wrap,
} from '@chakra-ui/react';
import { BsBox, BsLock } from 'react-icons/bs';
import { FaChartArea, FaGithub } from 'react-icons/fa';
import { CenterComponent } from '../../util/Ui';
import { HeaderHeadingComponent } from './HeaderHeadingComponent/HeaderHeadingComponent';

const chainIcons = [
  {
    name: 'Polygon',
    src: '/chainIcons/polygonChainLogo.svg',
  },
  {
    name: 'BSC',
    src: '/chainIcons/bscSmartChainLogo.svg',
  },
  {
    name: 'Ethereum',
    src: '/chainIcons/ethereumChainLogo.svg',
  },
];

const fetures = [
  {
    icon: BsBox,
    heading: 'Transparent',
    text: 'Each and every logic is written on smart contracts, which means each or any action is visible and can crosschecked.',
  },
  {
    icon: BsLock,
    heading: 'Secured',
    text: 'As we are fully decentralized & everything is on smart contract, which is most secured in the world.',
  },
  {
    icon: FaGithub,
    heading: 'Open Source',
    text: 'Our each and every code is open source on github. Anyone can audit our code, whether its frontend or smart contract logic.',
  },
];

export const Header = () => {
  return (
    <VStack
      w="full"
      zIndex={2}
      overflow="hidden"
      direction="column"
      borderRadius={[75]}
      py={[50, 100]}
      spacing={20}
      bgImage={'/headerBackground.png'}
      bgRepeat="no-repeat"
      bgPos="center"
      borderWidth={1}
      p={5}
    >
      <VStack w="full" px={[2]}>
        <HeaderHeadingComponent />
      </VStack>
      <Wrap spacing={10} justify="center">
        <CenterComponent
          style={{
            w: 'full',
            px: 10,
            boxSize: [250, 275, 300],
            backdropFilter: 'auto',
            backdropBlur: '10px',
          }}
        >
          <Icon as={FaChartArea} boxSize={100} color="twitter.500"></Icon>
        </CenterComponent>
        <VStack align="flex-start" spacing={5}>
          <Icon as={BsBox} boxSize={20} color="gray.300"></Icon>
          <VStack align="flex-start" spacing={0}>
            <Heading size="lg">Multichain Support</Heading>
            <Heading size="lg">Multi Currency Support</Heading>
            <Text>We are supporting many EVM Chains and working on to launch on non evem too.</Text>
          </VStack>
          <Wrap>
            {chainIcons?.map((chainObject, key) => {
              return (
                <Image key={key} src={chainObject?.src} boxSize={20}></Image>
              );
            })}
          </Wrap>
        </VStack>
      </Wrap>
      {/* <Wrap spacing={20} w="full" justify="space-between" maxW="6xl" p={5}>
        {fetures?.map((featuresObject, key) => {
          return (
            <Flex key={key} direction="column" gap={5} maxW={300}>
              <Icon as={featuresObject?.icon} boxSize={10}></Icon>
              <Heading>{featuresObject?.heading}</Heading>
              <Text fontSize="xl">{featuresObject?.text}</Text>
            </Flex>
          );
        })}
      </Wrap> */}
    </VStack>
  );
};
