import { Box, HStack, Spacer, useColorModeValue } from '@chakra-ui/react';
import { ConnectWalletButton } from '../ConnectWalletButton';
import { Logo } from '../Logo';
import MenuButtonComponent from './MenuButtonComponent';

export const Nav = () => {
  return (
    <Box
      py={2}
      px={3}
      w="full"
      borderRadius="full"
      // borderWidth="thin"
      position="sticky"
      top={0}
      zIndex={1111}
      backdropFilter="auto"
      backdropBlur={'20px'}
    >
      <HStack w="full">
        <Logo></Logo>
        <Spacer />
        <ConnectWalletButton showJazzicon={true}></ConnectWalletButton>
        <MenuButtonComponent />
      </HStack>
    </Box>
  );
};
