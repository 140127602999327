import { ChevronDownIcon, ChevronLeftIcon } from '@chakra-ui/icons';
import {
    Drawer,
    DrawerContent,
    DrawerOverlay,
    IconButton,
    useBreakpointValue,
    useColorModeValue,
    useDisclosure
} from '@chakra-ui/react';
import { useAccount } from 'wagmi';
import { NavMenuDrawer } from './Modals/NavMenuDrawer';
import { BsThreeDots } from 'react-icons/bs';

export default function MenuButtonComponent() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { address } = useAccount();
  return (
    <>
      <IconButton
        aria-label="Menu Button"
        icon={<BsThreeDots/>}
        onClick={onOpen}
        borderWidth="thin"
        borderRadius="xl"
      />
      <Drawer
        isOpen={isOpen}
        placement={useBreakpointValue(['bottom', 'bottom', 'right'])}
        onClose={onClose}
        preserveScrollBarGap={true}
      >
        <DrawerOverlay />
        <DrawerContent
          borderTopRightRadius={['3xl', 0]}
          borderTopLeftRadius={['3xl']}
          borderBottomLeftRadius={[0, 0, '3xl']}
          bgColor={useColorModeValue('white', 'gray.900')}
          boxShadow="lg"
        >
          <NavMenuDrawer address={address} onClose={onClose} />
        </DrawerContent>
      </Drawer>
    </>
  );
}
