import {
  Box,
  Button,
  Center,
  CircularProgress,
  CircularProgressLabel,
  Divider,
  HStack,
  Heading,
  Spacer,
  Tag,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react';
import {
  useGetStakingRewardByID,
  useStakeInfoMap,
} from '../../hooks/StakingHooks';
import { CenterComponent } from '../../util/Ui';
import { Counter } from '../Counter';
import {
  stakingDuration,
  supportedNetworkInfo,
} from '../../constants/SupportedNetworkInfo';
import { useContractWrite, useNetwork, useWaitForTransaction } from 'wagmi';
import { polygon } from 'wagmi/chains';
import { useEffect } from 'react';

function StakingInfoCard({ stakingId }: { stakingId: number }) {
  const toast = useToast();
  const { chains, chain } = useNetwork();
  const currentNetwork = supportedNetworkInfo[chain?.id ?? polygon?.id];
  const stakingInfoMap = useStakeInfoMap(stakingId);
  const stakingReward = useGetStakingRewardByID(stakingId);
  const rewardClaimed =
    (Number(stakingInfoMap?.data?.rewardClaimed) /
      Number(stakingInfoMap?.data?.value)) *
    100;

  const lockEndTime =
    Number(stakingInfoMap?.data?.duration) +
    Number(stakingInfoMap?.data?.startTime ?? 0);

  const {
    data: dataClaimStakingReward,
    isLoading: isLoadingClaimStakingReward,
    writeAsync: writeAsyncClaimStakingReward,
    reset: resetClaimStakingReward,
  } = useContractWrite({
    address: currentNetwork?.referralContractAddress,
    abi: currentNetwork?.referralContractInterface,
    functionName: 'claimStakingReward',
    args: [stakingId],
  });

  const {
    data: dataWaitForClaimStakingReward,
    isError: isErrorWaitForClaimStakingReward,
    isLoading: isLoadingWaitForClaimStakingReward,
    status: statusWaitForClaimStakingReward,
  } = useWaitForTransaction({
    hash: dataClaimStakingReward?.hash,
  });

  const proceedClaimStakingReward = async () => {
    try {
      await writeAsyncClaimStakingReward();
    } catch (err) {
      const error = JSON.stringify(err);
      toast({
        title: 'Error in transaction.',
        description: JSON.parse(error)?.shortMessage,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (statusWaitForClaimStakingReward === 'success') {
      toast({
        title: 'Tokens Claimed Successfully.',
        description: '',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      setTimeout(() => {
        resetClaimStakingReward();
      }, 5000);
    }
  }, [statusWaitForClaimStakingReward, toast, resetClaimStakingReward]);

  return (
    <CenterComponent>
      <VStack minW={250} maxW={300} w="full" spacing={5}>
        <VStack>
          <HStack w="full">
            <Text fontWeight="bold">Lock Id</Text>
            <Spacer />
            <Heading size="md" color="orange.500">
              #{Number(stakingId)}
            </Heading>
          </HStack>
          <Divider></Divider>
        </VStack>
        <VStack spacing={1}>
          <Tag size="lg" colorScheme="twitter" borderRadius="xl">
            MarsNext Locked
          </Tag>
          <Heading size="md">
            {Number(Number(stakingInfoMap?.data?.value) / 10 ** 18)?.toFixed(3)}
          </Heading>
          <Heading size="sm" color="orange.500">
            MarsNext
          </Heading>
        </VStack>
        <VStack spacing={1}>
          <Tag size="lg" colorScheme="twitter" borderRadius="xl">
            Unlock Counter
          </Tag>
        </VStack>
        <Counter timeinseconds={lockEndTime} size="sm"></Counter>
        <VStack>
          <Tag size="lg" colorScheme="twitter" borderRadius="xl">
            MarsNext Claimed
          </Tag>

          <CircularProgress
            size="150px"
            value={rewardClaimed}
            color="yellow.500"
            thickness="16px"
          >
            <CircularProgressLabel>
              <Center>
                <VStack spacing={0}>
                  <Heading size="md">
                    {Number(
                      Number(stakingInfoMap?.data?.rewardClaimed) / 10 ** 18
                    ).toFixed(3)}
                  </Heading>
                  <Heading size="xs" color="orange.500">
                    MarsNext
                  </Heading>
                  <Text fontSize="xx-small" color="twitter.500">
                    Claimed
                  </Text>
                </VStack>
              </Center>
            </CircularProgressLabel>
          </CircularProgress>
        </VStack>
        <VStack>
          <Tag size="lg" colorScheme="twitter" borderRadius="xl">
            Unlocking MarsNext
          </Tag>
          <Heading size="md">
            {Number(Number(stakingReward?.data) / 10 ** 18)?.toFixed(10)}
          </Heading>
          {/* <Heading size="sm" color="orange.500">
            MarsNext
          </Heading> */}
          {/* <Button
            borderRadius="xl"
            colorScheme="orange"
            bg="orange.500"
            _hover={{
              bg: 'orange.400',
            }}
          >
            Claim Staking Reward
          </Button> */}
        </VStack>
        <Button
          borderRadius="xl"
          colorScheme="green"
          isLoading={
            isLoadingClaimStakingReward || isLoadingWaitForClaimStakingReward
          }
          loadingText={'Claiming Unlocked Tokens'}
          onClick={proceedClaimStakingReward}
          // isDisabled={true}
        >
          Claim Unlocked Tokens
        </Button>
      </VStack>
    </CenterComponent>
  );
}

export default StakingInfoCard;
