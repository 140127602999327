import { Flex, Heading, VStack, useColorModeValue } from '@chakra-ui/react';
import { Nav } from './components/Nav/Nav';
import Footer from './components/Footer';
import { Outlet } from 'react-router-dom';

export const App = () => (
  <VStack w="full" bgColor={useColorModeValue('white', 'blackAlpha.500')}>
    <VStack w="full" maxW="8xl" px={5} pb={5}>
      <Nav></Nav>
      <Outlet></Outlet>
      <Footer></Footer>
      {/* <Heading>We are under maintainance. Please have some patience.</Heading> */}
    </VStack>
  </VStack>
);
