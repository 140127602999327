import {
  Center,
  Divider,
  HStack,
  Heading,
  Hide,
  Image,
  Show,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';

export const Logo = () => {
  return (
    <Link to="/">
      <Hide below="sm">
        <HStack>
          <Image src="/MarsNextRocketLogo.svg" alt="logo" w={5}></Image>
          <Center h={5}>
            <Divider orientation="vertical"></Divider>
          </Center>
          <Heading size="lg" fontSize="2xl">
            MarsNext
          </Heading>
        </HStack>
        {/* <Image src="/MarsNextLogoFull.svg" alt="logo" w={150}></Image> */}
      </Hide>
      <Show below="sm">
        <Image src="/MarsNextRocketLogo.svg" alt="logo" w={6}></Image>
      </Show>
    </Link>
  );
};
