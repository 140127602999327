import { WalletConnectLogoSVG } from '../assets';
import { Box, Button, Image, useBreakpointValue } from '@chakra-ui/react';
import { ConnectButton } from '@rainbow-me/rainbowkit';
// import { ConnectButton } from '@rainbow-me/rainbowkit';
import { shortenAddress, useEthers } from '@usedapp/core';
import { EthereumProvider } from '@walletconnect/ethereum-provider';
import { useWeb3Modal } from '@web3modal/react';
import React, { useEffect } from 'react';
import { jsNumberForAddress } from 'react-jazzicon';
import Jazzicon from 'react-jazzicon/dist/Jazzicon';
import { useAccount } from 'wagmi';

export const ConnectWalletButton = ({
  showJazzicon,
  userAddress,
}: {
  showJazzicon?: boolean;
  userAddress?: string;
}) => {
  const { isOpen, open, close, setDefaultChain } = useWeb3Modal();
  const { address } = useAccount();
  const conectWalletText = useBreakpointValue([
    'Connect',
    'Connect',
    'Connect Wallet',
  ]);

  return (
    <ConnectButton
      showBalance={false}
      accountStatus="full"
      chainStatus="full"
    ></ConnectButton>
  );
};
