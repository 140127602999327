import {
  ArrowForwardIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons';
import {
  Box,
  Button,
  Divider,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  Flex,
  HStack,
  Heading,
  Icon,
  IconButton,
  Spacer,
  Text,
  VStack,
  useBreakpointValue,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';
import { shortenAddress } from '@usedapp/core';
import { IconType } from 'react-icons';
import { FaDiscord, FaGithub, FaTelegram, FaTwitter } from 'react-icons/fa';
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';
import { AddressActionButtons } from '../../../components/AddressActionButtons';
import { ColorModeSwitcher } from '../../../components/ColorModeSwitcher';
import { ConnectWalletButton } from '../../../components/ConnectWalletButton';
import SocialMediaIcons from '../../../components/SocialMediaIcons';
import NavUserMenu from '../NavUserMenu';
import { Link } from 'react-router-dom';
import { BsBox } from 'react-icons/bs';
import { motion } from 'framer-motion';

const MotionIcon = motion(Icon);

export const NavMenuDrawer = ({
  address,
  onClose,
}: {
  address: string | undefined;
  onClose: () => void;
}) => {
  const { colorMode } = useColorMode();
  const JazziconSize = useBreakpointValue([25, 30, 40]);
  const socialMediaIcons: IconType[] = [
    FaTwitter,
    FaTelegram,
    FaGithub,
    FaDiscord,
  ];
  const iconButtonIcon = useBreakpointValue([
    <ChevronDownIcon key={1} />,
    <ChevronDownIcon key={2} />,
    <ChevronRightIcon key={3} />,
  ]);
  return (
    <>
      <DrawerHeader>
        <HStack w="full">
          <Spacer />
          <IconButton
            aria-label="Menu Close Button"
            icon={iconButtonIcon}
            variant="outline"
            w="fit-content"
            borderColor={useColorModeValue('gray.500', 'white')}
            opacity={0.5}
            size="sm"
            _hover={{
              bg: 'transparent',
              opacity: 1,
            }}
            onClick={onClose}
            rounded="full"
          />
        </HStack>

        {address ? (
          <HStack>
            <Jazzicon
              diameter={JazziconSize}
              seed={jsNumberForAddress(address)}
            ></Jazzicon>
            <Text fontWeight={400} fontSize={['sm', 'md']}>
              {shortenAddress(address)}
            </Text>
            <AddressActionButtons address={address} />
          </HStack>
        ) : (
          <Heading size="md" textAlign="center" py={5}>
            Please connect wallet to continue
          </Heading>
        )}
      </DrawerHeader>
      <DrawerBody>
        <Flex h="full" align="center" justify="center" w="full">
          {address ? (
            <NavUserMenu userAddress={address} onClick={onClose} />
          ) : (
            <Box onClick={onClose}>
              <ConnectWalletButton />
            </Box>
          )}
        </Flex>
      </DrawerBody>
      <DrawerFooter>
        <VStack w="full" spacing={5}>
          <Button
            minW={200}
            h={14}
            borderRadius="3xl"
            colorScheme="gray"
            onClick={onClose}
            // bg="orange.300"
            // _hover={{
            //   bg: 'orange.400',
            // }}
            as={Link}
            to={'/stake'}
            borderWidth="thin"
            leftIcon={
              <MotionIcon
                as={BsBox}
                animate={{
                  y: [0, -5, 0],
                }}
                transition={{
                  duration: 1,
                  repeat: Infinity,
                }}
              ></MotionIcon>
            }
            rightIcon={
              <MotionIcon
                as={ArrowForwardIcon}
                animate={{
                  x: [0, 5, 0],
                }}
                transition={{
                  duration: 1,
                  repeat: Infinity,
                }}
              ></MotionIcon>
            }
          >
            Stake Now
          </Button>
          <HStack spacing={5}>
            <SocialMediaIcons />
          </HStack>
          <Divider />
          <HStack w="full">
            <Heading size="sm">
              {colorMode === 'dark' ? 'Dark Mode' : 'Light Mode'}
            </Heading>
            <Spacer />
            <ColorModeSwitcher />
          </HStack>
        </VStack>
      </DrawerFooter>
    </>
  );
};
