import { Center, Divider, HStack, Heading, Image } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

export const LogoFull = () => {
  return (
    <Link to="/">
      <HStack>
        <Image src="/MarsNextRocketLogo.svg" alt="logo" w={5}></Image>
        <Center h={5}>
          <Divider orientation="vertical"></Divider>
        </Center>
        <Heading size="lg" fontSize="2xl">
          MarsNext
        </Heading>
      </HStack>
    </Link>
  );
};
